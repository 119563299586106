export const projectInitialState = {
    project: localStorage.getItem('localStorageProject') || ''
};

export const projectActions = {
    setProject: (state, payload) => {
        localStorage.setItem('localStorageProject', payload);

        return {
            project: payload
        }
    }
};