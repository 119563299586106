import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {checkMultipleAxios, helperCatchErrors, selectIconComponent} from "../../../helper/Helper";
import Api from "../../../helper/Api";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import {format} from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar, faCogs, faEnvelopeOpen, faFilePdf, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Token from "../../../helper/Token";
import config from "../../../config/config";
import pages from "../../../config/pages";
import {Box, FormHelperText, Modal} from "@material-ui/core";
import ResultPdf from "../../resultPdf/resultPdf";
import Form from "react-bootstrap/cjs/Form";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Spinner from "../../spinner/Spinner";
import LoadingDataGrid from "../../loadingDataGrid/LoadingDataGrid";
import Notifications from "../../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import SelectProjectWrapper from "../../selectProject/selectProjectWrapper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ApiFilterBuilder from "../../../helper/ApiFilterBuilder";
import {FormattedMessage} from "react-intl.macro";
import TextField from "@material-ui/core/TextField";
import ButtonBootstrap from "react-bootstrap/cjs/Button";

const NormalPins = () => {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [cachedProject, setCachedProject] = useState(state.project);
    const [reload, setReload] = React.useState({0: false});
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [loadingData, setLoadingData] = useState(true);
    const [languages, setLanguages] = useState(false);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    const [loadingResult, setLoadingResult] = useState(false);

    // Modal
    const [modalPinId, setModalPinId] = React.useState(null);
    const [modalResultOpen, setModalResultOpen] = React.useState(false);
    const [modalLanguage, setModalLanguage] = React.useState('');
    const [pinName, setPinName] = React.useState('');
    const [resultPdfBlob, setResultPdfBlob] = useState({});
    const [resultPdfName, setResultPdfName] = useState('');

    const [comment, setComment] = useState('');
    const [normgroup, setNormgroup] = useState('');
    const [normgroups, setNormgroups] = useState(false);
    const [availableNormgroups, setAvailableNormgroups] = useState(false);
    const [loadingNormgroups, setLoadingNormgroups] = useState(true);
    const [modalEditPinOpen, setModalEditPinOpen] = React.useState(false);

    // Notification
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState(GlobalTransIntl('notification_send_success', intl));

    // Edit Modal Notifications
    const [notificationErrorEditModal, setNotificationErrorEditModal] = useState(false);
    const [errorMessageEditModal, setErrorMessageEditModal] = React.useState('');

    const assessmentUrl = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ],
        'procedures'
    );

    const [toolbarItems] = React.useState(Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN') ?
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTransIntl('pin', intl)
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTransIntl('name', intl)
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            }
        ] :
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTransIntl('pin', intl)
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTransIntl('name', intl)
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            },
            {
                type: 'select',
                getUrl: assessmentUrl,
                name: 'procedureObject',
                label: GlobalTransIntl('assessment', intl),
                filterOperator: 'equals'
            },
        ]
    );

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
        setNotificationErrorEditModal(false);
    };

    const getLanguages = useCallback(() => {
        Api.getLanguages(
            (res) => {
                setLanguages(res.data['hydra:member']);
                setLoadingLanguages(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [state.token, catchErrors, cancelToken.token]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                setNormgroups(res.data['hydra:member']);
                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token]);

    useEffect(() => {
        if (state.project && (cachedProject && cachedProject !== state.project)) {
            setCachedProject(state.project);
            setReload({0:true});
        } else if (state.project && !cachedProject) {
            setCachedProject(state.project);
        }
    }, [cachedProject, state.project]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getLanguages();
            getNormgroups();
        }
    }, [mounted, getLanguages, getNormgroups]);

    useEffect(() => {
        let checkAllAxios = checkMultipleAxios(
            [
                loadingLanguages
            ],
            [
                {
                    name: 'language',
                    value: languages
                }
            ],
            intl
        )

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            setLoadingData(false);
        }
    }, [loadingLanguages, languages, intl, showError]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const columns = [
        { field: 'token', headerName: GlobalTrans('pin'), width: 200 },
        {
            field: 'procedureObject', headerName: GlobalTrans('field_assessment'), flex: 1,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'normgroup', headerName: GlobalTrans('field_normgroup'), flex: 1,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value.name}>{params.value.name}</span>;
                } else {
                    return '-';
                }
            }
        },
        { field: 'firstName', headerName: GlobalTrans('first_name'), width: 140,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return params.row.user?.firstName;
                } else {
                    return '-';
                }
            } },
        { field: 'lastName', headerName: GlobalTrans('last_name'), width: 140,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return params.row.user?.lastName;
                } else {
                    return '-';
                }
            } },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            hide: true,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return `${params.row?.user?.firstName || ''} ${params.row?.user?.lastName || ''}`
                }

                return '';
            }
        },
        { field: 'comment', headerName: GlobalTrans('field_comment'), width: 160,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'pinMails', headerName: GlobalTrans('field_receiver'), width: 160,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value.map((item) => item.email);
                } else {
                    return '-';
                }
            },
            renderCell: (params) => {
                if (params.value !== '-') {
                    return (
                        <span title={params.value}>
                            {params.value.map((item, key) => {
                                return ((key > 0) ? ',' : '') + item
                            })}
                        </span>
                    )
                }
            }
        },
        {
            field: 'startedAt',
            headerName: GlobalTrans('field_carried_out'),
            type: 'date',
            width: 140,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
    ];

    const reactivatePin = (props) => {
        console.log(props);
    }

    const TitleResultAction = (props) => (
        <>
            <IconButton
                onClick={() => handleOpen(props.props)}
                aria-label={GlobalTrans('title_result')}
                title={GlobalTrans('title_result')}
                disabled={!props.props.row?.user}
            >
                <FontAwesomeIcon icon={faChartBar} size={"xs"}/>
            </IconButton>
            <IconButton onClick={() => openEditPinModal(props.props)} aria-label={GlobalTransIntl('title_edit', intl)}
                        title={GlobalTransIntl('title_edit', intl)}>
                <FontAwesomeIcon icon={faCogs} size={"xs"}/>
            </IconButton>
        </>
    );

    const columnActions = (props) => {
        if (Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')) {
            return <TitleResultAction props={props}/>;
        }

        return (
            <React.Fragment>
                <IconButton onClick={() => reactivatePin(props)} aria-label={GlobalTrans('title_reactivate')} title={GlobalTrans('title_reactivate')} disabled>
                    <FontAwesomeIcon icon={faEnvelopeOpen} size={"xs"}/>
                </IconButton>
                <TitleResultAction props={props}/>
            </React.Fragment>
        );
    }

    const handleChangeLanguage = (event) => {
        setModalLanguage(event.target.value);
    };

    const handleOpen = (props) => {
        const fullName = props.row.user.firstName + ' ' + props.row.user.lastName;

        setPinName(fullName);
        setModalPinId(props.id);
        setModalResultOpen(true);
    };

    const openEditPinModal = (props) => {
        const row = props.row;

        setNotificationErrorEditModal(false);
        setModalPinId(props.id);
        setModalEditPinOpen(true);

        setComment(row.comment);
        setAvailableNormgroups(row.procedureObject.availableNormgroups)

        if (row.normgroup?.['@id']) {
            setNormgroup(row.normgroup['@id']);
        }
    }

    const handleClose = () => {
        setModalLanguage('');

        setModalResultOpen(false);
        setLoadingResult(false);
        setResultPdfBlob({});
        setResultPdfName('');
    };

    const handleOnClickPdf = () => {
        setLoadingResult(true);
    }

    const handleOnClickDetails = () => {
        // set details ID
        dispatch({
            type: 'setResultDetails',
            payload: modalPinId
        });
        dispatch({
            type: 'setPage',
            payload: pages.PINS_RESULTS_DETAILS
        });
    }

    const getAvailableNormgroups = () => {
        return availableNormgroups?.length ? normgroups.filter(normgroup => availableNormgroups.find(availableNormgroup => availableNormgroup === normgroup['@id'])) : [];
    }

    const handleEditPinClose = () => {
        setModalEditPinOpen(false);
    };

    const onSubmitEditPin = (e) => {
        e.preventDefault();

        const data = {
            comment,
            normgroup
        };

        setNotificationErrorEditModal(false);

        axios.patch(config.apiUrl + `/pins/` + modalPinId, data, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'content-type': 'application/merge-patch+json'}
        }))
            .then((res) => {
                if (res.status === 200) {
                    handleEditPinClose();
                    setSuccessMessage(GlobalTransIntl('notification_success', intl));
                    setNotificationSuccess(true);
                    setReload({0: true});

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                } else {
                    setErrorMessageEditModal("Status " + res.status);
                    setNotificationErrorEditModal(true);
                }
            })
            .catch(catchErrors);
    }

    const getModals = () => {
        return (
            <>
                <Modal
                open={modalResultOpen}
                onClose={handleClose}
                className={'MuiModal-root'}
            >
                <div className={'main-modal modal-size-md'}>
                    {
                        (
                            loadingResult &&
                            <ResultPdf
                                pinId={modalPinId}
                                pinName={pinName}
                                locale={modalLanguage || config.defaultLocale}
                                resultPdfBlob={resultPdfBlob}
                                setResultPdfBlob={setResultPdfBlob}
                                resultPdfName={resultPdfName}
                                setResultPdfName={setResultPdfName}
                            />
                        )
                        ||
                        <React.Fragment>
                            <Form.Group controlId="formBasicLanguage">
                                <h2 className={'text-align-center'}>
                                    {GlobalTransIntl('pin_results_modal_headline', intl)}<br/> {pinName}
                                </h2>
                            </Form.Group>
                            <Form.Group controlId="formBasicLanguage">
                                <FormControl variant={'outlined'} className={'pin-result--form-control'}>
                                    <InputLabel>
                                        {GlobalTransIntl('language', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="pin-results--modal-language-select"
                                        value={modalLanguage}
                                        onChange={handleChangeLanguage}
                                        label={GlobalTransIntl('language', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        <MenuItem value={''} data-name={GlobalTransIntl('translation_default', intl)}>{GlobalTransIntl('translation_default', intl)}</MenuItem>
                                        {
                                            languages.map((item, key) =>
                                                <MenuItem value={item.code} key={key}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                    <FormHelperText>{GlobalTransIntl('error_no_selected_language', intl)}</FormHelperText>
                                </FormControl>
                            </Form.Group>
                            <Row className={'justify-content-md-center'}>
                                <Col xs={'auto'}>
                                    <IconButton color="primary"
                                                className={'pin-result--modal-button-pdf'}
                                                aria-label={GlobalTransIntl('resultpdf', intl)}
                                                title={GlobalTransIntl('resultpdf', intl)}
                                                component="span"
                                                onClick={handleOnClickPdf}>
                                        <FontAwesomeIcon icon={faFilePdf}/>
                                    </IconButton>
                                </Col>
                                {
                                    !Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN') &&
                                    <Col xs={'auto'}>
                                        <IconButton color="primary"
                                                    className={'pin-result--modal-button-details'}
                                                    aria-label={GlobalTransIntl('result_details', intl)}
                                                    title={GlobalTransIntl('result_details', intl)}
                                                    component="span"
                                                    onClick={handleOnClickDetails}>
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                        </IconButton>
                                    </Col>
                                }
                            </Row>
                        </React.Fragment>
                    }
                </div>
            </Modal>
                <Modal
                    open={modalEditPinOpen}
                    onClose={handleEditPinClose}
                    className={'MuiModal-root'}
                >
                    <div className={'main-modal modal-size-lg'}>
                        <Spinner show={loadingLanguages} rowClass={'p-5'}/>
                        {
                            !loadingNormgroups &&
                            <form onSubmit={onSubmitEditPin}>
                                <h2 className={'text-align-center'}>
                                    <FormattedMessage id='pin.edit.modal.headline' defaultMessage='Edit pin'/>
                                </h2>
                                <Box mb={2}>
                                    <Notifications
                                        error={notificationErrorEditModal}
                                        errorMessage={errorMessageEditModal}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <FormControl
                                        variant={'outlined'}
                                        className={'pin-results--form-control'}
                                        id={'pin-results-normgroup'}
                                        disabled={getAvailableNormgroups().length === 0}
                                        required
                                    >
                                        <InputLabel>
                                            {GlobalTransIntl('normgroup', intl)}
                                        </InputLabel>
                                        <Select
                                            id="pin-results--normgroup-select"
                                            value={normgroup}
                                            onChange={(event) => {
                                                setNormgroup(event.target.value)
                                            }}
                                            label={GlobalTransIntl('normgroup', intl)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                getAvailableNormgroups().map((item) =>
                                                    <MenuItem value={item['@id']} key={item.id}
                                                              data-name={item.name}
                                                              selected={item['@id'] === normgroup}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        label={GlobalTransIntl('comment', intl)}
                                        id={'pin-results--comment'}
                                        value={comment}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setComment(e.target.value)
                                        }}
                                        multiline
                                        minRows={4}
                                    />
                                </Box>
                                <ButtonBootstrap variant="primary" type="submit" className='pin-result--submit'>
                                    {GlobalTransIntl('button_save', intl)}
                                </ButtonBootstrap>
                            </form>
                        }
                    </div>
                </Modal>
            </>
        );
    }

    const handleThreeSixtyCheckbox = () => {
        dispatch({
            type: 'setPinResult',
            payload: config.pinResults.threeSixty
        })
    }

    const content = (
        <React.Fragment>
            <Spinner show={loadingData} rowClass={'p-5'}/>
            {
                !loadingData &&
                <Box position={'relative'}>
                    <Box position={'absolute'} top={0} right={0} mt={2} mr={2} zIndex={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={false}
                                    onChange={handleThreeSixtyCheckbox}
                                    name="three_sixty_checkbox"
                                    color="primary"
                                />
                            }
                            label={GlobalTransIntl('three_sixty_pins', intl)}
                        />
                    </Box>
                    <Box pt={8}>
                        <LoadingDataGrid
                            apiUrl={'pins'}
                            columns={columns}
                            toolbarItems={toolbarItems}
                            columnActions={columnActions}
                            columnActionsWidth={190}
                            dataGridFilter={
                                [
                                    {name: 'status', value: '/api/pin_statuses/5'},
                                    {name: 'project', value: state.project},
                                    {name: 'exists', index: 'threeSixtyRole', value: false},
                                ]
                            }
                            reload={reload}
                            deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
                        />
                    </Box>
                    <Notifications
                        cols={{
                            xs: 12,
                            lg: {
                                span: 6,
                                offset: 3
                            }
                        }}
                        success={notificationSuccess}
                        error={notificationError}
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                    />
                    {getModals()}
                </Box>
            }
        </React.Fragment>
    );

    return (
        <Paper>
            {
                (
                    (!state.project) &&
                    <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>
                )
                ||
                content
            }
        </Paper>
    );
};

export default NormalPins;