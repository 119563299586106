import MultipleChoice, {MultipleChoiceConfig} from "./MultipleChoice";
import GridElement, {GridElementConfig} from "./GridElement";
import TextElement, {TextElementConfig} from "./TextElement";
import HtmlElement, {HtmlElementConfig} from "./HtmlElement";
import QuestionnaireElement, {QuestionnaireElementConfig} from "./QuestionnaireElement";
import ForcedChoiceElement, {ForcedChoiceElementConfig} from "./ForcedChoiceElement";
import TextInputElement, {TextInputElementConfig} from "./TextInputElement";
import DropdownElement, {DropdownElementConfig} from "./DropdownElement";
import RandomMultipleChoiceElement, {RandomMultipleChoiceConfig} from "./RandomMultipleChoiceElement";
import VideoRecordElement, {VideoRecordElementConfig} from "./VideoRecordElement";
import RightAndWrongChoiceElement, {RightAndWrongChoiceConfig} from "./RightAndWrongChoiceElement";
import MailLayoutElement, {MailLayoutElementConfig} from "./MailLayoutElement";
import MailTasksElement, {MailTasksConfig} from "./MailTasksElement";
import TextareaElement, {TextareaElementConfig} from "./TextareaElement";
import VideoElement, {VideoElementConfig} from "./VideoElement";
import AdaptiveElement, {AdaptiveElementConfig} from "./AdaptiveElement";
import ImageElement, {ImageElementConfig} from "./ImageElement";
import TableElement, {TableElementConfig} from "./TableElement";

const elements = [
    {
        'type': 'grid',
        'component': GridElement,
        'config': GridElementConfig
    },
    {
        'type': 'text',
        'component': TextElement,
        'config': TextElementConfig
    },
    {
        'type': 'textarea',
        'component': TextareaElement,
        'config': TextareaElementConfig
    },
    {
        'type': 'html',
        'component': HtmlElement,
        'config': HtmlElementConfig
    },
    {
        'type': 'table',
        'component': TableElement,
        'config': TableElementConfig
    },
    {
        'type': 'questionnaire',
        'component': QuestionnaireElement,
        'config': QuestionnaireElementConfig
    },
    {
        'type': 'radio',
        'component': MultipleChoice,
        'config': MultipleChoiceConfig
    },
    {
        'type': 'sort',
        'component': ForcedChoiceElement,
        'config': ForcedChoiceElementConfig
    },
    {
        'type': 'textInput',
        'component': TextInputElement,
        'config': TextInputElementConfig
    },
    {
        'type': 'dropdown',
        'component': DropdownElement,
        'config': DropdownElementConfig
    },
    {
        'type': 'random_radio',
        'component': RandomMultipleChoiceElement,
        'config': RandomMultipleChoiceConfig
    },
    {
        'type': 'rightAndWrong',
        'component': RightAndWrongChoiceElement,
        'config': RightAndWrongChoiceConfig
    },
    {
        'type': 'image',
        'component': ImageElement,
        'config': ImageElementConfig
    },
    {
        'type': 'video',
        'component': VideoElement,
        'config': VideoElementConfig
    },
    {
        'type': 'video_record',
        'component': VideoRecordElement,
        'config': VideoRecordElementConfig
    },
    {
        'type': 'mailLayout',
        'component': MailLayoutElement,
        'config': MailLayoutElementConfig,
        'ocs': true
    },
    {
        'type': 'mailTasks',
        'component': MailTasksElement,
        'config': MailTasksConfig,
        'ocs': true
    },
    {
        'type': 'adaptive_tree',
        'component': AdaptiveElement,
        'config': AdaptiveElementConfig
    },
];

export default elements;