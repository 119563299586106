import Dropdown from "react-bootstrap/cjs/Dropdown";
import React, {useCallback, useEffect, useState} from "react";
import {useStore} from "../../store/useStore";
import Button from "react-bootstrap/cjs/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import InputBase from "@material-ui/core/InputBase";
import UseFormatMessage from "../../helper/UseFormatMessage";
import {useIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";
import axios from "axios";
import config from "../../config/config";
import Spinner from "../spinner/Spinner";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import PropTypes from "prop-types";
import {findProject} from "../../helper/Helper";

function SelectProject(props) {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [projectsLoaded, setProjectsLoaded] = useState(false);

    const getProjects = useCallback(() => {
        axios.get(config.apiUrl + `/projects`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data && res.data['hydra:member'].length) {
                    const projects = res.data['hydra:member'];

                    if (state.project && projects.findIndex(project => project['@id'] === state.project) === -1) {
                        dispatch({
                            type: 'setProject',
                            payload: ''
                        });
                    }

                    dispatch({
                        type: 'setProjects',
                        payload: projects
                    });
                }
                setProjectsLoaded(true);
            })
            .catch(() => {});
    }, [state.token, state.project, cancelToken.token, dispatch]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getProjects();
        }
    }, [mounted, getProjects]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    useEffect(() => {
        // When projects are loaded from another source, then cancel getProjects
        if (state.projects.length >= 1) {
            if(state.projects.length === 1) {
                dispatch({
                    type: 'setProject',
                    payload: state.projects[0]['@id']
                });
            }

            cancelToken.cancel();
            setProjectsLoaded(true);
        }
    }, [state.projects, cancelToken, dispatch]);

    const messages = defineMessages({
        'select_project_filter': {
            id: "select_project.filter",
            defaultMessage: "Type to filter..."
        },
    });

    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const selectProjectToggle = React.forwardRef(({children, onClick}, ref) => (
        <Button ref={ref} className='projectSwitch' variant={"link"} onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        >
            {children} <FontAwesomeIcon icon={faCaretDown}/>
        </Button>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
            const [projectValue, setProjectValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <div className='select-project'>
                        <InputBase
                            autoFocus
                            placeholder={UseFormatMessage(intl, messages.select_project_filter)}
                            classes={{
                                root: 'select-project-input--root',
                                input: 'select-project-input--input',
                            }}
                            onChange={(e) => setProjectValue(e.target.value)}
                            value={projectValue}
                        />
                    </div>
                    <ul className="list-unstyled select-project-list">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !projectValue || child.props.children.toLowerCase().startsWith(projectValue.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const getDropdown = () => {
        if (!projectsLoaded) {
            return null;
        } else if (state.projects.length === 0) {
            return <span className={'ml-2'}>{GlobalTransIntl('no_projects', intl)}</span>;
        } else if (state.projects.length === 1) {
            const project = state.projects[0];
            return <span className={'ml-2'}>{project.name}</span>;
        }

        return (
            <Dropdown>
                <Dropdown.Toggle as={selectProjectToggle} id={"dropdown-select-project-" + props.id}>
                    {findProject(state.project, state.projects)?.name || 'none'}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu} className={'select-project-menu'}>
                    {
                        state.projects.map(
                            item => (
                                <Dropdown.Item
                                    as="button"
                                    key={item.id}
                                    onClick={
                                        () => dispatch({
                                            type: 'setProject',
                                            payload: item['@id']
                                        })
                                    }
                                    active={state.project === item['@id']}
                                >
                                    {item.name}
                                </Dropdown.Item>
                            )
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    return (
        <React.Fragment>
            <Spinner show={!projectsLoaded} size={props.size}/>
            {getDropdown()}
        </React.Fragment>
    );
}

SelectProject.propTypes = {
    id: PropTypes.string.isRequired
}

export default SelectProject;