import React, {useCallback, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {format} from "date-fns";
import {faRedo} from "@fortawesome/free-solid-svg-icons/faRedo";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import {helperCatchErrors} from "../../helper/Helper";
import {useIntl} from "react-intl";
import Notifications from "../notifications/Notifications";
import SelectProjectWrapper from "../selectProject/selectProjectWrapper";
import Token from "../../helper/Token";

function PinsStarted () {
    const { state } = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [reload, setReload] = React.useState({0: false});
    const [cachedProject, setCachedProject] = useState(state.project);

    // Notification
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationDeleteSuccess, setNotificationDeleteSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
    }, []);

    const showSuccess = useCallback(() => {
        setNotificationSuccess(true);
    }, []);

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationDeleteSuccess(false);
        setNotificationError(false);
    };

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    useEffect(() => {
        if (state.project && (cachedProject && cachedProject !== state.project)) {
            setCachedProject(state.project);
            setReload({0:true});
        } else if (state.project && !cachedProject) {
            setCachedProject(state.project);
        }
    }, [cachedProject, state.project]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const columns = [
        { field: 'token', headerName: GlobalTrans('pin'), width: 200 },
        {
            field: 'procedureObject', headerName: GlobalTrans('field_assessment'), width: 150,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        { field: 'firstName', headerName: GlobalTrans('first_name'), width: 140 },
        { field: 'lastName', headerName: GlobalTrans('last_name'), width: 140 },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            hide: true,
            valueGetter: (params) =>
                `${params.getValue(params.id, 'firstName') || ''} ${
                    params.getValue(params.id, 'lastName') || ''
                }`,
        },
        {
            field: 'startedAt',
            headerName: GlobalTrans('started_at'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        { field: 'email', headerName: GlobalTrans('field_email'), width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
        { field: 'comment', headerName: GlobalTrans('field_comment'), width: 160,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
    ];

    const reactivatePin = (props) => {
        const data = {
            reset: true
        };

        resetNotifications();

        axios.patch(config.apiUrl + `/pins/` + props.id, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/merge-patch+json'}}))
            .then(res => {
                if (res.status === 200) {
                    showSuccess();
                    setReload({0: true});

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                } else {
                    showError("Status " + res.status);
                }
            })
            .catch(catchErrors);
    }

    const columnActions = (props) => {
        if (Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')) {
            return null;
        }

        return (
            <IconButton onClick={() => reactivatePin(props)} aria-label={GlobalTrans('title_reactivate')}
                        title={GlobalTrans('title_reactivate')}>
                <FontAwesomeIcon icon={faRedo} size={"xs"}/>
            </IconButton>
        )
    }

    const content = (
        <React.Fragment>
            <LoadingDataGrid
                apiUrl={'pins'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'token',
                        label: GlobalTrans('pin')
                    },
                    {
                        type: 'text',
                        name: 'fullName',
                        label: GlobalTrans('full_name')
                    },
                    {
                        type: 'text',
                        name: 'comment',
                        label: GlobalTransIntl('comment', intl)
                    }
                ]}
                columnActions={columnActions}
                dataGridFilter={
                    [
                        {name: 'status', value: '/api/pin_statuses/3'},
                        {name: 'project', value: state.project}
                    ]
                }
                reload={reload}
                deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
            />
            <Notifications
                cols={{
                    xs: 12,
                    lg: {
                        span: 6,
                        offset: 3
                    }
                }}
                success={notificationSuccess}
                delete={notificationDeleteSuccess}
                error={notificationError}
                errorMessage={errorMessage}
            />
        </React.Fragment>
    );

    return (
        <Paper>
            {
                (
                    (!state.project) &&
                    <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>
                )
                ||
                content
            }
        </Paper>
    );
}

export default PinsStarted;