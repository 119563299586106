import React, {useCallback, useImperativeHandle, useMemo, useState} from 'react';
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Form from "react-bootstrap/cjs/Form";
import TextField from "@material-ui/core/TextField";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {deepCopy, selectIconComponent} from "../../helper/Helper";
import MenuItem from "@material-ui/core/MenuItem";
import {useIntl} from "react-intl";
import PropTypes from 'prop-types';
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Validation from "../../helper/Validation";
import IconButton from "@material-ui/core/IconButton";

function ReceiverForm(props, ref) {
    const intl = useIntl();
    const pinMailElement = useMemo(() => ({
        email: '',
        language: null
    }), []);

    const preparePropsReceiver = () => {
        if (props?.receiver?.length) {
            return props.receiver.map(receiver => {
                if (receiver?.language?.['@id']) {
                    return {
                        ...receiver,
                        language: receiver.language['@id']
                    }
                }

                return receiver;
            });
        }

        return [pinMailElement];
    }

    const [receiver, setReceiver] = useState(preparePropsReceiver());
    const [validReceiver, setValidReceiver] = useState(Array(receiver.length).fill(true));

    const validateReceiver = useCallback(() => {
        let validationSuccess = true,
            localValidReceiver = [...validReceiver];

        if (receiver.length > 1 || receiver[0]?.email) {
            receiver.forEach((element, index) => {
                let isValueValid = Validation.isEmail(element.email);

                localValidReceiver[index] = isValueValid;

                validationSuccess = isValueValid;
            });

            setValidReceiver(localValidReceiver);
        }

        return validationSuccess;
    }, [validReceiver, receiver]);

    useImperativeHandle(ref, () => ({
        validate() {
            return validateReceiver();
        },
        submit() {
            if (receiver.length === 1 && receiver[0].email === '') {
                props.submitFunction([]);
            } else {
                props.submitFunction(receiver);
            }
        },
        reset() {
            setReceiver([pinMailElement]);
            setValidReceiver([true]);
        }
    }), [props, receiver, validateReceiver, pinMailElement]);

    const deleteRow = (key) => {
        setReceiver((prev) => prev.filter((item,itemKey) => itemKey !== key ));
        setValidReceiver((prev) => prev.filter((item,itemKey) => itemKey !== key ));
    }

    const addNewReceiverElement = () => {
        setReceiver(prev => {
            const newPinMails = [...prev];

            newPinMails.push(pinMailElement);

            return newPinMails;
        });

        setValidReceiver(
            (prevState) => ([
                ...prevState,
                true
            ])
        );
    }

    const updateReceiver = useCallback((receiverKey, index, value) => {
        setReceiver(prev => {
            const newPrev = deepCopy(prev);

            newPrev[receiverKey][index] = value;

            return newPrev;
        })
    }, []);

    const getReceiverRows = () => {
        return (
            receiver.map((item, key) =>
                <Row key={key}>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="formBasicReceiver"
                                    className={'pins--create--form-group-infinite-elements'}>
                            <TextField error={!validReceiver[key]}
                                       id={`pin-create--receiver-${key}`}
                                       label={GlobalTransIntl('receiver', intl) + ' ' + (key + 1)}
                                       value={item.email}
                                       variant="outlined"
                                       onChange={
                                           (e) => {
                                               updateReceiver(key, 'email', e.target.value);
                                           }
                                       }
                                       helperText={GlobalTransIntl('error_field_email', intl)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={5}>
                        <Form.Group controlId="formBasicReceiverLanguage">
                            <FormControl
                                variant={'outlined'}
                                className={'project-create--form-control'}
                                id={`project-create--receiver-language-${key}`}
                            >
                                <InputLabel>
                                    {GlobalTransIntl('receiver_language', intl)}
                                </InputLabel>
                                <Select
                                    id="project-create--language-select"
                                    value={item.language || ''}
                                    onChange={
                                        (e) => {
                                            updateReceiver(key, 'language', e.target.value);
                                        }
                                    }
                                    label={GlobalTransIntl('language', intl)}
                                    IconComponent={selectIconComponent}
                                >
                                    <MenuItem value={''} data-name={GlobalTransIntl('translation_default', intl)}>{GlobalTransIntl('translation_default', intl)}</MenuItem>
                                    {
                                        props.languages.map((item) =>
                                            <MenuItem value={item['@id']}
                                                      key={item.id}
                                                      data-name={item.name}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={1}>
                        <IconButton aria-label={GlobalTransIntl('delete', intl)}
                                    title={GlobalTransIntl('delete', intl)}
                                    size="small" className={'mt-3'}
                                    onClick={() => deleteRow(key)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </IconButton>
                    </Col>
                </Row>
            )
        );
    }

    return (
        <div ref={ref}>
            {getReceiverRows()}
            <Form.Group controlId="formAddReceiver">
                <MaterialButton
                    variant="contained"
                    color="default"
                    className={''}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                    onClick={addNewReceiverElement}
                    aria-label={GlobalTransIntl('add_receiver', intl)}
                    title={GlobalTransIntl('add_receiver', intl)}
                >
                    {GlobalTransIntl('add_receiver', intl)}
                </MaterialButton>
            </Form.Group>
        </div>
    );
}

export default React.forwardRef(ReceiverForm);

ReceiverForm.propTypes = {
    receiver: PropTypes.array,
    languages: PropTypes.array.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    setNotificationError: PropTypes.func.isRequired,
    submitFunction: PropTypes.func.isRequired
};